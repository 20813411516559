<template>
    <div>
        <div style="margin-top: 10px;margin-bottom: 0;" class="divider">
            <span style="color:#666;padding-left: 5px"><v-icon style="color:#666!important">pie_chart</v-icon> Daily Attendance Inspection ({{nd.format('YYYY-MM-DD')}})</span>
        </div>
        <!-- <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart> -->
        <apexchart type="area" width="100%" height="300" :options="attendanceChartOptions"
                   :series="attendanceSeries"></apexchart>

    </div>
</template>

<script>

    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();

    export default {
        data() {
            return {
                attendanceChartOptions: {},
                attendanceSeries: [],
                nd
            }
        },
        mounted() {
            this.getAttendanceChartData();
        },
        methods: {
            getAttendanceChartData() {
                this.$rest.get('api/report/academic/today-attendance').then(res => {
                    let presentee = [];
                    let absentees = [];
                    let leave = [];
                    let allData = {};
                    res.data.data.map(rd => {
                            res.data.grades.map(g => {
                                if (rd.name === g.toString()) {
                                    allData[g.toString()] = {
                                        leave: rd.leave,
                                        absent: rd.absent,
                                        present: rd.present
                                    }
                                }
                            });
                        },
                    );
                    res.data.grades.map(grade => {
                        if (!['', undefined, null].includes(allData[grade])) {
                            presentee.push(allData[grade].present);
                            absentees.push(allData[grade].absent);
                            leave.push(allData[grade].leave);
                        } else {
                            presentee.push("0");
                            absentees.push("0");
                            leave.push("0");
                        }
                    });
                    this.attendanceChartOptions = {
                        // colors: ['#66DA26', '#2E93fA', '#FF9800', '#546E7A', '#E91E63'],
                        colors: ['#66DA26', '#ff5252','#FF9800'],

                        chart: {
                            type: 'area',
                            id: 'daily attendance',
                            toolbar: {
                                show: false
                            },
                        },
                        colors: ['#00C853', '#D32F2F', '#FF6F00'],
                        dataLabels: {
                            enabled: false
                        },
                        stroke: {
                            curve: 'smooth'
                        },
                        grid: {
                            row: {
                                colors: ['#efeeee', 'transparent'], // takes an array which will be repeated on columns
                                opacity: 0.5
                            },
                        },
                        xaxis: {
                            categories: res.data.grades,
                        },
                    };

                    this.attendanceSeries = [{
                        name: 'Present',
                        data: presentee,
                        colors: ['red']
                    }, {
                        name: 'Absent',
                        data: absentees
                    }, {
                        name: 'Leave',
                        data: leave
                    }];
                });
            }
        }
    }
</script>