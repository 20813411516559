<template>
  <div>
    <div style="margin-top: 10px;margin-bottom: 0;" class="divider">
      <span style="color:#666;padding-left: 5px"
        ><v-icon style="color:#666!important">pie_chart</v-icon> Collection for
        current month</span
      >
    </div>
    <apexchart
      v-if="cashBookChart.data.length"
      type="donut"
      width="350"
      :options="cashBookChart.options"
      :series="cashBookChart.data"
    ></apexchart>
    <not-found
      v-else-if="!loading"
      text="Looks like you have no records for this month."
    ></not-found>
  </div>
</template>

<script>
// import NotFound from './../../../../components/svg/NotFound'

export default {
  components: {
    // NotFound
  },
  data() {
    return {
      loading: false,
      cashBookChart: {
        options: {
          legend: {
            position: "bottom",
          },
          labels: ["By Cash", "By Bank", "By Khalti"],
        },
        data: [],
      },
    };
  },
  mounted() {
    this.billingMonthlyCollection();
  },
  methods: {
    billingMonthlyCollection() {
      this.loading = true;
      this.$rest
        .get("/api/report/billing/monthly-collection")
        .then(({ data }) => {
          let $this = this;
          if (data.cash) {
            this.cashBookChart.data.push(data.cash);
          }
          if (data.cheque) {
            this.cashBookChart.data.push(data.cheque);
          }

          if (data.khalti) {
            this.cashBookChart.data.push(data.khalti);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
