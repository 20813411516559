<template>
  <div>
    <div style="margin-top: 10px;margin-bottom: 0;" class="divider">
      <span style="color:#666;padding-left: 5px"
        ><v-icon style="color:#666!important">pie_chart</v-icon> Grade Wise
        Students</span
      >
    </div>
    <apexchart
      type="pie"
      width="350"
      :options="studentChart.options"
      :series="studentChart.data"
    ></apexchart>
  </div>
</template>

<script>
export default {
  data() {
    return {
      studentChart: {
        options: {
          legend: {
            position: "bottom",
          },
          labels: [],
        },
        data: [],
      },
    };
  },
  mounted() {
    this.fillGradesChart();
  },
  methods: {
    fillGradesChart(params) {
      // this.$rest.get('/api/grades?rowsPerPage=50&descending=false').then(({data}) => {
      //     let $this = this;
      //     data.data.map(function (item) {
      //         $this.studentChart.options.labels.push(item.name);
      //         $this.studentChart.data.push(item.student_count);
      //     });
      // })
    },
  },
};
</script>
